import { DownloadOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDownloadReportMutation } from 'app/services/report';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as qs from 'qs';
import ability from 'common/casl/ability';
import { Icon } from '@iconify/react';
export interface IJobDataTableProps {
  data: any;
  onTableChange: (e: any) => void;
  page: number;
  pageSize: number;
  total: number;
  isLoading: boolean;
  filters?: any;
}
const ThemeJobDataTable = ({
  data,
  onTableChange,
  page,
  pageSize,
  isLoading,
  total,
  filters
}: IJobDataTableProps) => {
  const nav = useNavigate();
  const [canSelfAssign, setCanSelfAssign] = useState(false);
  const [downloadReport, { data: reportData, isLoading: reportLoading, reset }] =
    useDownloadReportMutation();

  useEffect(() => {
    setCanSelfAssign(ability.can('SELF_ASSIGN', 'AGENT_DASHBOARD'));
  }, []);

  const handleViewClick = (jobID: any, status: string) => {
    nav(`/jobs/${jobID}?type=assign&status=${status}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'jobID',
      headerName: 'Job ID',
      renderCell: (params: any) => (
        <span style={{ cursor: 'pointer' }} onClick={() => nav(`/jobs/${params.value}`)}>
          {params.value}
        </span>
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'techID',
      headerName: 'Technician ID',
      renderCell: (params: any) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => nav(`/technicians/${params.row.technicianID}`)}>
          {params.value}
        </span>
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'customerPhone',
      headerName: 'Customer ID',
      renderCell: (params: any) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => nav(`/customers/${params.row.customerID}`)}>
          {params.value}
        </span>
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 1
    },
    { field: 'jobDate', headerName: 'Date', sortable: false, disableColumnMenu: true, flex: 1 },
    { field: 'jobTime', headerName: 'Time', sortable: false, disableColumnMenu: true, flex: 1 },
    {
      field: 'serviceCategoryName',
      headerName: 'Service Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'statusName',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'client',
      headerName: 'Clients',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.5
    },
    {
      field: 'jobSourceRefNo',
      headerName: 'Job Ref',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: any) => (
        <span style={{ cursor: 'pointer' }} onClick={() => nav(`/jobs/${params.row.jobID}`)}>
          {params.value}
        </span>
      )
    },
    {
      field: 'agentName',
      headerName: 'Agent',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: any) => {
        if (!params.value?.trim()) {
          if (canSelfAssign) {
            return (
              <Tooltip title="Take job">
                <Button
                  onClick={() =>
                    handleViewClick(params?.row?.jobID, params?.row?.followupStatusName)
                  }>
                  <Icon icon="oi:action-undo" width="1.3rem" height="1.3rem" />
                </Button>
              </Tooltip>
            );
          }
          return 'N/A';
        }
        return params.formattedValue;
      }
    },
    { field: 'stateName', headerName: 'State', sortable: false, disableColumnMenu: true, flex: 1 }
  ];

  useEffect(() => {
    if (reportData && reportData.data) {
      window.open(reportData?.data, '_blank');
      reset();
    }
    return () => {};
  }, [reportData, reportLoading]);

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <IconButton
          disabled={reportLoading}
          onClick={() => {
            let f;
            if (filters) {
              f = qs.parse(filters);
            }
            downloadReport({
              reportType: 'JOB_CARD_REPORT',
              dates: [
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD')
              ],
              jobCardFilters: f
            });
          }}>
          {reportLoading ? <CircularProgress size={20} /> : <DownloadOutlined />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'} height={'100%'}>
          <DataGrid
            rows={data ?? []}
            columns={columns}
            rowCount={total}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            onPaginationModelChange={onTableChange}
            paginationModel={{
              pageSize,
              page
            }}
            loading={isLoading}
            getRowId={(row) => row.jobID}
            // sx={{ width: '100%', maxWidth: '100%' }}
            autoHeight
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': {
                borderBottom: 'none'
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9' // Light gray for odd rows
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: '#ffffff' // White for even rows
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E0E9ED', // Light gray header background
                color: '#333333',
                fontWeight: '600 !important',
                textAlign: 'center',
                borderBottom: '2px solid #e0e0e0',
                height: '40px !important',
                minHeight: '40px !important'
              },
              '& .MuiDataGrid-columnHeader': {
                fontWeight: 'bold',
                padding: '0 8px'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textAlign: 'center',
                color: '#333333',
                fontWeight: '600 !important'
              },
              '& .MuiDataGrid-cellContent': {
                textAlign: 'center'
              },
              '& .MuiDataGrid-row': {
                height: 60 // Row height
              }
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ThemeJobDataTable;
