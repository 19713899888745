import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';

export const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listDashboardData: builder.query({
      query: () => `/admin/dashboard`
    }),
    listJobQue: builder.query({
      providesTags: ['JobQue'],
      keepUnusedDataFor: 0,
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, status, followupStatus, createdDate } = params;
        let queryUrl = `/admin/followup/que?limit=${limit}&offset=${offset}`;

        if (typeof status === 'number') {
          queryUrl += `&status=${status}`;
        }

        if (typeof followupStatus === 'number') {
          queryUrl += `&followupStatus=${followupStatus}`;
        }

        if (typeof createdDate === 'string') {
          queryUrl += `&createdDate=${createdDate}`;
        }

        return queryUrl;
      }
    }),
    listAgentJobs: builder.query({
      providesTags: ['JobQue'],
      keepUnusedDataFor: 0,
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, status, followupStatus, createdDate } = params;

        let queryUrl = `/admin/followup/agent-jobs?limit=${limit}&offset=${offset}`;

        if (typeof status === 'number') {
          queryUrl += `&status=${status}`;
        }

        if (typeof followupStatus === 'number') {
          queryUrl += `&followupStatus=${followupStatus}`;
        }

        if (typeof createdDate === 'string') {
          queryUrl += `&createdDate=${createdDate}`;
        }

        return queryUrl;
      }
    }),
    getDashboardV2: builder.query<
      {
        totalJobs: number;
        totalOngoingJobs: number;
        totalPendingJobs: number;
        totalCancelledJobs: number;
        totalCompletedJobs: number;
        items: {
          jobID: number;
          technicianID: string;
          techID: string;
          customerID: string;
          customerPhone: string;
          jobDate: string;
          jobTime: string;
          client?: string;
          jobSourceRefNo?: string;
          agentName?: string;
          stateName?: string;
          serviceCategoryName?: string;
          statusName?: string;
        }[];
        total: number;
      },
      {
        pagination: {
          limit: number;
          offset: number;
        };
        filters: string;
      }
    >({
      query: (params) => {
        let queryUrl = `/admin/v2/dashboard?limit=${params.pagination.limit}&offset=${params.pagination.offset}`;

        if (params.filters) {
          queryUrl += `&${params.filters}`;
        }

        return queryUrl;
      },
      providesTags: ['Dashboard']
    }),
    universalSearch: builder.query<
      {
        items: any[];
        total: number;
      },
      {
        page: number;
        q: string;
      }
    >({
      query: (params) => {
        let queryUrl = `/admin/v2/universal-search?page=${params.page}`;

        if (params.q) {
          queryUrl += `&q=${params.q}`;
        }

        return queryUrl;
      }
    })
  })
});

export const {
  useListDashboardDataQuery,
  useLazyListJobQueQuery,
  useListJobQueQuery,
  useLazyListAgentJobsQuery,
  useListAgentJobsQuery,
  useGetDashboardV2Query,
  useLazyGetDashboardV2Query,
  useUniversalSearchQuery,
  useLazyUniversalSearchQuery
} = dashboardApi;
