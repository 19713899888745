import { FC } from 'react';
import { TuneOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';

export interface IAgentRosterHeaderProps {
  handleViewPlan: () => void;
  handleOpenFilterModal: () => void;
}

const AgentRosterHeader: FC<IAgentRosterHeaderProps> = (props) => {
  const { handleViewPlan, handleOpenFilterModal } = props;

  return (
    <Grid container spacing={3} justifyContent="space-between" sx={{ mb: 4 }}>
      <Grid item>
        <Typography variant="h2">Agent Roster</Typography>
      </Grid>
      <Grid item></Grid>
      <Grid
        item
        sx={{ p: 0, paddingLeft: '0px !important', paddingTop: '0px !important' }}
        alignContent={'center'}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button size="small" variant="contained" onClick={() => handleViewPlan()}>
              View plan
            </Button>
          </Grid>

          <Grid item>
            <IconButton onClick={handleOpenFilterModal}>
              <TuneOutlined sx={{ color: '#D9D9D9' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AgentRosterHeader;
