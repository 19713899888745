import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Alert,
  FormControl,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import {
  IAdminRecordRosterEntryInputItem,
  useAdminRecordRosterPlanEntriesMutation
} from 'app/services/shift';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

export interface IUpdateAgentEntryDialogProps {
  open: boolean;
  onClose: () => void;
  masterData: any;
  selectedCells: any[];
  onPlanChange?: (e: any, cb?: () => void) => void;
}
const UpdateAgentEntryDialog: FC<IUpdateAgentEntryDialogProps> = ({
  open,
  onClose,
  masterData,
  selectedCells,
  onPlanChange
}) => {
  const [shift, setShift] = useState<number | null>(null);
  const [duty, setDuty] = useState<number | null>(null);
  const [status, setStatus] = useState('');
  const [leaveReason, setLeaveReason] = useState<number | null>(null);
  const [showOtherReason, setShowOtherReason] = useState(false);
  const [otherReasonText, setOtherReasonText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [recordRosterEntry, { isLoading }] = useAdminRecordRosterPlanEntriesMutation();

  const handleSubmit = async () => {
    let errors = [];
    if (shift === null) {
      errors.push('shift');
    }
    if (duty === null) {
      errors.push('duty');
    }
    if (status === '') {
      errors.push('status');
    }
    if (status === 'LEAVE' && leaveReason === null) {
      errors.push('leave reason');
    }

    //@ts-ignore
    if (leaveReason === 'other' && otherReasonText.trim() === '') {
      errors.push('other leave reason text');
    }

    if (errors.length > 0) {
      setErrorMessage(`Error: Please select a ${errors.join(', ')}.`);
      return;
    }

    setErrorMessage('');

    const dutyDetails = masterData.duties.find((d: any) => d.dutyID === duty);
    const shiftDetails = masterData.shiftDefinitions.find((s: any) => s.shiftID === shift);
    const statusDetails = masterData.workConditions.find((s: any) => s.conditionCode === status);
    const leaveReasonDetails =
      status === 'LEAVE'
        ? masterData.leaveReasons.find((s: any) => s.leaveReasonID === leaveReason)
        : null;

    if (onPlanChange) {
      onPlanChange({
        shift: shiftDetails.shiftID,
        shiftName: shiftDetails.shiftName,
        duty: dutyDetails.dutyID,
        dutyName: dutyDetails.dutyDescription,
        workConditionCode: statusDetails.conditionCode,
        workConditionName: statusDetails.description,
        statusColorCode: statusDetails?.colorCode,
        dutyCode: dutyDetails.dutyCode,
        dutyColorCode: dutyDetails?.colorCode,
        shiftColorCode: shiftDetails?.colorCode,
        leaveReasonID: leaveReasonDetails?.leaveReasonID,
        leaveReasonName: leaveReasonDetails?.reasonDescription,
        leaveReasonColorCode: leaveReasonDetails?.colorCode,
        leaveReasonCode: leaveReasonDetails?.reasonCode,
        otherReasonText,
        selectedCells
      });

      onClose();
      return;
    }

    const year = moment(selectedCells[0].row, 'DD-MMM-YYYY').year();
    const month = moment(selectedCells[0].row, 'DD-MMM-YYYY').month() + 1;
    const agentID = selectedCells[0].field;
    const entries: any = selectedCells.map((cell: any, i: number) => {
      const obj = {
        id: cell.row,
        date: cell.row,
        [agentID]: {
          agentID: agentID,
          dutyID: dutyDetails.dutyID,
          dutyName: dutyDetails.dutyDescription,
          leaveReasonID: leaveReasonDetails?.leaveReasonID,
          leaveReasonName: leaveReasonDetails?.reasonDescription,
          leaveReasonColorCode: leaveReasonDetails?.colorCode,
          leaveReasonCode: leaveReasonDetails?.reasonCode,
          otherReasonText: otherReasonText,
          shiftID: shiftDetails.shiftID,
          shiftName: shiftDetails.shiftName,
          workConditionCode: statusDetails.conditionCode,
          workConditionName: statusDetails.description,
          statusColorCode: statusDetails?.colorCode,
          dutyCode: dutyDetails.dutyCode,
          dutyColorCode: dutyDetails?.colorCode,
          shiftColorCode: shiftDetails?.colorCode
        }
      };

      return obj;
    });
    try {
      await recordRosterEntry({
        input: {
          planMonth: month,
          planYear: year,
          isCreatePlan: false,
          rosterEntries: entries
        }
      });
      toast.success('Entry Updated', { duration: 2000 });
      onClose();
    } catch (err) {}
  };

  useEffect(() => {
    if (
      masterData?.workConditions?.filter((e: any) => e.conditionCode === 'ACTIVE')?.length > 0
    ) {
      setStatus('ACTIVE');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Agent Entry</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '16px' }}>
            {errorMessage}
          </Alert>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Shift</Typography>
            <Grid container spacing={1}>
              {masterData?.shiftDefinitions?.map(
                (shiftOption: { shiftID: number; shiftName: string }) => (
                  <Grid item key={shiftOption.shiftID}>
                    <Button
                      variant={shift === shiftOption.shiftID ? 'contained' : 'outlined'}
                      onClick={() => setShift(shiftOption.shiftID)}>
                      {shiftOption.shiftName}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Duty</Typography>
            <Grid container spacing={1}>
              {masterData?.duties?.map((dutyOption: { dutyID: number; dutyCode: string }) => (
                <Grid item key={dutyOption.dutyID}>
                  <Button
                    variant={duty === dutyOption.dutyID ? 'contained' : 'outlined'}
                    onClick={() => setDuty(dutyOption.dutyID)}
                    style={{ textTransform: 'capitalize' }}>
                    {dutyOption.dutyCode.toLowerCase().replace(/_/g, ' ')}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '16px' }}>
            <Typography variant="h6">Status</Typography>
            <Grid container spacing={1}>
              {masterData?.workConditions?.map((statusOption: { conditionCode: string }) => (
                <Grid item key={statusOption.conditionCode}>
                  <Button
                    variant={status === statusOption.conditionCode ? 'contained' : 'outlined'}
                    onClick={() => {
                      setStatus(statusOption.conditionCode);
                      setLeaveReason(null);
                      setOtherReasonText('');
                      setShowOtherReason(false);
                    }}
                    style={{ textTransform: 'capitalize' }}>
                    {statusOption.conditionCode.toLowerCase().replace(/_/g, ' ')}
                  </Button>
                </Grid>
              ))}
            </Grid>
            {status === 'LEAVE' && (
              <FormControl fullWidth style={{ marginTop: '16px' }}>
                <InputLabel id="leave-reason-label">Leave Reason</InputLabel>
                <Select
                  label="Leave Reason"
                  size="small"
                  labelId="leave-reason-label"
                  value={leaveReason}
                  onChange={(e) => {
                    const value = e.target.value as any;
                    setLeaveReason(value);
                    setShowOtherReason(value === 'other');
                    setOtherReasonText('');
                  }}
                  displayEmpty
                  fullWidth>
                  {masterData?.leaveReasons?.map(
                    (reason: {
                      reasonCode: string;
                      leaveReasonID: number;
                      reasonDescription: string;
                    }) => (
                      <MenuItem key={reason.leaveReasonID} value={reason.leaveReasonID}>
                        {reason.reasonDescription}
                      </MenuItem>
                    )
                  )}
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {showOtherReason && (
                  <TextField
                    fullWidth
                    label="Please specify"
                    value={otherReasonText}
                    onChange={(e) => setOtherReasonText(e.target.value)}
                    multiline
                    rows={4}
                    variant="outlined"
                    style={{ marginTop: '16px' }}
                  />
                )}
              </FormControl>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={handleSubmit} variant="contained">
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAgentEntryDialog;
