import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { ModalProps } from './types';

const ModalDialog: React.FC<ModalProps> = ({
  open,
  title = null,
  children,
  cancelName = 'Cancel',
  submitName = 'Save',
  onClose,
  onSubmit,
  loading = false,
  disabled = false,
  width = 'md',
  extraActions
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={width}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: 'start', ml: 2, mb: 2 }}>
        {cancelName && (
          <Button size="medium" onClick={onClose} variant="contained">
            {cancelName}
          </Button>
        )}
        {submitName && (
          // <Button type="submit" size="medium" onClick={onSubmit} variant="contained">
          //   {submitName}
          // </Button>
          <LoadingButton
            type="submit"
            size="medium"
            // color='secondary'
            onClick={onSubmit}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            loading={loading}
            disabled={disabled}>
            {submitName}
          </LoadingButton>
        )}

        {extraActions?.map((action, index) => (
          <Button key={index} size="small" onClick={action.onClick} variant={action.variant}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
