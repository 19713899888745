import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import UpdateAgentEntryDialog from './UpdateAgentEntryDialog';
import { Box } from '@mui/material';

export interface ICustomDataGridProps {
  data: any;
  isLoading: boolean;
  masterData: any;
  onPlanChange?: (e: any, cb: () => void) => void;
  formattedEntries: any[];
  setFormattedEntries: (e: any) => void;
}

const CustomDataGrid = ({
  data,
  isLoading,
  masterData,
  onPlanChange,
  formattedEntries,
  setFormattedEntries
}: ICustomDataGridProps) => {
  const [selectedCells, setSelectedCells] = useState<any[]>([]);
  const [activeColumn, setActiveColumn] = useState<any>(null);
  const [mouseEnteredCells, setMouseEnteredCells] = useState(new Set());
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const isCellSelected = (id: any, field: any) => {
    const ids = selectedCells.find((cell) => cell.row === id && cell.field === field);
    return !!ids;
  };

  const handleMouseDown = (params: any) => {
    if (selectedCells?.length) {
      if (params.field !== selectedCells[0].field) {
        setSelectedCells([]);
      }
    }

    if (activeColumn === params.field) {
      const cellKey = `${params.id}-${params.field}`;
      setMouseEnteredCells((prev) => new Set(prev).add(cellKey));
      setSelectedCells((prev) => {
        const exists = prev.some((cell) => cell.row === params.id && cell.field === params.field);
        if (!exists) {
          return [...prev, { row: params.id, field: activeColumn }];
        }
        return prev;
      });
    } else {
      if (isCellSelected(params.id, params.field)) {
        setSelectedCells((prev) =>
          prev.filter((cell) => !(cell.row === params.id && cell.field === params.field))
        );
      } else {
        setSelectedCells((prev) => [...prev, { row: params.id, field: params.field }]);
      }

      setActiveColumn(params.field);
      const cellKey = `${params.id}-${params.field}`;
      setMouseEnteredCells((prev) => new Set(prev).add(cellKey));
    }
  };

  const handleMouseMove = (params: any) => {
    if (activeColumn === params.field) {
      const cellKey = `${params.id}-${activeColumn}`;
      const exists = selectedCells.some(
        (cell) => cell.row === params.id && cell.field === activeColumn
      );
      if (exists && !mouseEnteredCells.has(cellKey)) {
        setSelectedCells((prev) =>
          prev.filter((cell) => !(cell.row === params.id && cell.field === activeColumn))
        );
        mouseEnteredCells.add(cellKey);
      } else if (!exists && !mouseEnteredCells.has(cellKey)) {
        setSelectedCells((prev) => [...prev, { row: params.id, field: activeColumn }]);
        mouseEnteredCells.add(cellKey);
      }
    } else {
      const cellKey = `${params.id}-${activeColumn}`;
      if (mouseEnteredCells.has(cellKey)) {
        setSelectedCells((prev) =>
          prev.filter((cell) => !(cell.row === params.id && cell.field === activeColumn))
        );
        mouseEnteredCells.delete(cellKey);
      }
    }

    //in case this cell is selected remove selection
  };

  const handleMouseUp = () => {
    setMouseEnteredCells(new Set());
    setActiveColumn(null);
  };

  const renderCellWithSelection = (params: any) => {
    const isSelected = isCellSelected(params.id, params.field);
    let backgroundColor = '#fff';
    let color = '#000';

    const formattedValue = params?.formattedValue;

    if (formattedValue?.workConditionCode == 'ACTIVE') {
      backgroundColor = formattedValue?.shiftColorCode;
    }

    if (formattedValue?.workConditionCode == 'WEEK_OFF') {
      color = 'red';
    }

    if (formattedValue?.workConditionCode == 'LEAVE') {
      if (formattedValue?.leaveReasonCode == 'HALF_DAY') {
        backgroundColor = formattedValue?.leaveReasonColorCode;
      }

      if (formattedValue?.leaveReasonCode == 'EMERGENCY') {
        backgroundColor = formattedValue?.leaveReasonColorCode;
      }

      if (formattedValue?.leaveReasonCode == 'NORMAL') {
        backgroundColor = formattedValue?.leaveReasonColorCode;
      }
    }

    return (
      <div
        onMouseDown={(event) => {
          handleMouseDown(params);
        }}
        onMouseUp={handleMouseUp}
        onMouseMove={(event) => {
          if (event.buttons === 1) {
            handleMouseMove(params);
          }
        }}
        style={{
          backgroundColor: isSelected ? 'lightblue' : backgroundColor,
          color,
          padding: '8px',
          border: '1px solid #ccc',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative'
        }}>
        <span
          style={{
            display: 'inline-block',
            maxWidth: '15ch',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '11px',
            fontWeight: 'bold'
          }}>
          {params?.formattedValue?.displayText}
        </span>
        {isSelected && (
          <EditIcon
            style={{
              fontSize: '16px',
              color: '#666',
              cursor: 'pointer',
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              backgroundColor: 'lightblue'
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleOpenEditModal();
            }}
          />
        )}
      </div>
    );
  };

  const columns: GridColDef[] =
    data?.dates?.length && data?.dates?.[0]?.entries?.length
      ? [
          { field: 'date', headerName: 'Date', width: 150 },
          {
            field: 'day',
            headerName: 'Date',
            renderCell: (params: any) => moment(params.row.date, 'DD-MMM-YYYY').format('dddd'),
            width: 150
          },
          ...data?.dates?.[0]?.entries?.map((el: any, index: number) => {
            return {
              field: el.agentID,
              headerName: el.agentName,
              width: 150,
              renderCell: (params: any) => renderCellWithSelection(params)
            };
          })
        ]
      : [];

  useEffect(() => {
    const newData: any = [];
    data?.dates?.forEach((date: any) => {
      const newObj: any = {
        id: date.date,
        date: date.date
      };

      date.entries.forEach((entry: any) => {
        let displayText = '';

        if (entry.workConditionCode === 'ACTIVE' && entry.shiftName) {
          displayText = entry.shiftName + ' - ' + entry?.dutyName;
        } else if (entry.workConditionCode === 'WEEK_OFF') {
          displayText = 'Week OFF';
        } else if (entry.leaveReasonName) {
          displayText = entry.leaveReasonName;
        } else if (entry.otherReasonText) {
          displayText = entry.otherReasonText;
        }

        newObj[entry.agentID] = {
          agentID: entry.agentID,
          dutyID: entry.dutyID,
          dutyName: entry.dutyName,
          dutyColorCode: entry.dutyColorCode,
          dutyCode: entry.dutyCode,
          leaveReasonID: entry.leaveReasonID,
          leaveReasonName: entry.leaveReasonName,
          leaveReasonCode: entry.leaveReasonCode,
          leaveReasonColorCode: entry.leaveReasonColorCode,
          otherReasonText: entry.otherReasonText,
          shiftID: entry.shiftID,
          shiftName: entry.shiftName,
          shiftColorCode: entry.shiftColorCode,
          workConditionCode: entry.workConditionCode,
          workConditionName: entry.workConditionName,
          workConditionColorCode: entry.workConditionColorCode,
          selectedCells: entry.selectedCells,
          displayText: displayText
        };
      });

      newData.push(newObj);
    });

    setFormattedEntries(newData);
  }, [data]);

  return (
    <Box
      sx={{
        minHeight: 400,
        //maxWidth: 'calc(100vw - 300px)',
        // width: '1',
        userSelect: 'none'
      }}>
      <DataGrid
        sx={{ userSelect: 'none', minHeight: 400 }}
        rows={formattedEntries || []}
        columns={columns}
        getRowId={(row) => row.date}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isLoading}
        disableColumnSelector
        disableDensitySelector
        disableEval
        disableVirtualization
      />

      <UpdateAgentEntryDialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        masterData={masterData}
        selectedCells={selectedCells}
        onPlanChange={
          onPlanChange
            ? (v) =>
                onPlanChange(v, () => {
                  setSelectedCells([]);
                })
            : undefined
        }
      />
    </Box>
  );
};

export default CustomDataGrid;
