import { Ability, SubjectRawRule } from '@casl/ability';
import { jwtDecode /*, JwtPayload*/ } from 'jwt-decode';

export const SUBJECTS = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  BANNERS: 'BANNERS',
  CONTENT: 'CONTENT',
  CUSTOMERS: 'CUSTOMERS',
  DOCUMENT: 'DOCUMENT',
  EARNINGS: 'EARNINGS',
  GARAGE: 'GARAGE',
  JOBS: 'JOBS',
  OFFERS: 'OFFERS',
  PAYMENTS: 'PAYMENTS',
  PERMISSIONS: 'PERMISSIONS',
  PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS',
  REPORTS: 'REPORTS',
  SERVICES: 'SERVICES',
  SERVICE_CATEGORIES: 'SERVICE_CATEGORIES',
  SETTINGS: 'SETTINGS',
  TECHNICIANS: 'TECHNICIANS',
  VEHICLES: 'VEHICLES',
  VEHICLE_BRANDS: 'VEHICLE_BRANDS',
  USERS: 'USERS',
  DOCUMENTS: 'DOCUMENTS',
  AGREEMENTS: 'AGREEMENTS',
  JOB_TYPES: 'JOB_TYPES',
  LANGUAGES: 'LANGUAGES',
  STATES: 'STATES',
  SHIFTS: 'SHIFTS',
  AGENT_DASHBOARD: 'AGENT_DASHBOARD',
  SUPERVISOR_DASHBOARD: 'SUPERVISOR_DASHBOARD',
  INSPECTIONS: 'INSPECTIONS',
  KPI: 'KPI',
  MONTHLY_WISE_REPORT: 'MONTHLY_WISE_REPORT',
  CLIENTS: 'CLIENTS',
  TELECMI: 'TELECMI',
};

export type ActionType =
  | 'READ'
  | 'UPDATE'
  | 'DELETE'
  | 'CREATE'
  | 'LIST'
  | 'MANAGE'
  | 'SELF_ASSIGN';

export type AppAbility = Ability<[ActionType, string]>;
// getRules is for generating rules automaticaly
export const getRules = () => {
  let rules: SubjectRawRule<string, string, any>[] = [];
  const accessToken: string | null = localStorage.getItem('accessToken') ?? null;
  if (!accessToken) return rules;
  const token = jwtDecode<any>(accessToken ?? ''); // as JwtPayload;
  for (const key in token?.permissions) {
    const rule: SubjectRawRule<string, string, any>[] = [];
    token?.permissions[key].forEach((permission: string) => {
      rule.push(Object.assign({}, { subject: key, action: permission }));
    });
    rules = [...rules, ...rule];
  }
  rules.push({ subject: 'HOME', action: 'READ' });
  return rules;
};

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Ability(getRules(), {
  detectSubjectType: (subject) => (typeof subject === 'string' ? subject : subject.subject)
});

//HINT: check the name of accessToken in localStorage
