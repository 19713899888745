import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import DrawerHeader from 'layout/DrawerHeader';
import { drawerWidth } from 'utils/constants';
import SidebarMenu from './SidebarMenu';
import DrawerProvider from './drawerProvider';
import LogoImg from '../../assets/images/autoaid-logo.png';

export type MenuItemType = {
  text: string;
  link: string;
  icon: () => JSX.Element;
  type: string;
  permission?: string;
  items?: MenuItemType[];
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box'
}));

export default function Sidebar({
  openDrawer,
  handleDrawerClose
}: {
  openDrawer: boolean;
  handleDrawerClose: () => void;
}): React.ReactElement {
  return (
    <DrawerProvider isDrawerOpen={openDrawer}>
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader></DrawerHeader>
        <SidebarMenu />
      </Drawer>
    </DrawerProvider>
  );
}

// export default SideBar;
