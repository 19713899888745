export const drawerWidth = 240;
export const DEFAULT_LIMIT = 25;

export enum Technician_Status {
  NEW_CONTACT = 1,
  PENDING = 2,
  ACTIVE = 3,
  INACTIVE = 4
}

export enum Job_Status {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  CANCELLED_CUSTOMER = 4,
  CANCELLED_TECHNICIAN = 5,
  CANCELLED_ADMIN = 6,
  IN_PROGRESS = 7,
  COMPLETED = 8,
  FAILED = 9,
  INVOICED = 10,
  PAYMENT_FAILED = 11,
  PAID = 12
}

export function getStatusLabel(value: number): string | undefined {
  const statusKeys = Object.keys(Technician_Status) as (keyof typeof Technician_Status)[];

  for (const key of statusKeys) {
    if (Technician_Status[key] === value) {
      return key;
    }
  }

  return undefined; // Return undefined if the value is not found
}

export const allMonths = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

export const permission = [
  'BANNERS',
  'CONTENT',
  'CUSTOMERS',
  'DOCUMENT',
  'EARNINGS',
  'GARAGE',
  'JOBS',
  'OFFERS',
  'PAYMENTS',
  'PERMISSIONS',
  'PUSH_NOTIFICATIONS',
  'REPORTS',
  'SERVICES',
  'SERVICE_CATEGORIES',
  'SETTINGS',
  'TECHNICIANS',
  'VEHICLES',
  'VEHICLE_BRANDS'
];

export const crud = {
  UPDATE: 'UPDATE',
  READ: 'READ',
  CREATE: 'CREATE',
  DELETE: 'DELETE'
};

export enum ReportType {
  JOB_REPORT = 'JOB_REPORT',
  TECHNICIAN_REPORT = 'TECHNICIAN_REPORT',
  CUSTOMER_REPORT = 'CUSTOMER_REPORT',
  MONTHLY_WISE_REPORT = 'MONTHLY_WISE_REPORT',
  OFFER_REPORT = 'OFFER_REPORT',
  AGREEMENT_REPORT = 'AGREEMENT_REPORT',
}
export const jobSources = ['ENQUIRY_CALLS', 'WORK_REQUEST', 'RSA_PROVIDER'];
export const jobProviders = ['ALLIANZ', 'TVS', 'EUROP_ASSISTANCE', 'GLOBAL_ASSURE', 'OTHERS'];
export const jobProviders1 = [
  'Autocon-EW Pre-inspection',
  'Autocon-EW Re-inspection',
  'Autocon-EW Claim Inspection'
];

export type SuccessResponce = {
  message: string;
  success: boolean;
};

export type ListResponse<ItemData> = {
  items: ItemData[];
  total: number;
};

export type QueryParams = {
  limit: number;
  offset: number;
  [key: string]: string | number | undefined | string[] | number[];
};
