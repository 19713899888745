//@ts-nocheck
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import NotFound from 'pages/not-found';
import Layout from 'layout';
import Loadable from 'components/Loadable';
import Users from 'pages/Users';
import BannerItem from 'pages/promotion/banner/BannerItem';
import AgentDashboard from 'pages/dashboard/components/AgentDashboard';
import ListNotifications from 'pages/push-notifications/ListNotifications';
import JobCard from 'pages/JobCard';
import { AnimatePresence } from 'framer-motion';
import CreateRosterPlan from 'pages/agent-roster/CreatePlan';
import RosterPlan from 'pages/agent-roster/RosterPlan';
import AgentRoster from 'pages/agent-roster';
import SubscriptionPlansListing from 'pages/settings/SubPages/SubscriptionPlans';
import { Box } from '@mui/material';

const DashBoard = Loadable(lazy(() => import('pages/dashboard')));
const AuthLogin = Loadable(lazy(() => import('pages/auth/Login')));
const Garage = Loadable(lazy(() => import('pages/garage')));
const Technicians = Loadable(lazy(() => import('pages/technicians')));
const TechnicianInfo = Loadable(lazy(() => import('pages/technicians/TechnicianInfo')));
const TechnicianRating = Loadable(lazy(() => import('pages/technicians/TechnicianRating')));
const Settings = Loadable(lazy(() => import('pages/settings')));
const Services = Loadable(lazy(() => import('pages/services/service')));
const ServiceCategory = Loadable(lazy(() => import('pages/services/category')));
const Vehicle = Loadable(lazy(() => import('pages/vehicle')));
const VehicleBrandList = Loadable(lazy(() => import('pages/vehicle/brands')));
const DocumentList = Loadable(lazy(() => import('pages/document/DocumentList')));
const BannerList = Loadable(lazy(() => import('pages/promotion/banner/BannerList')));
const Customers = Loadable(lazy(() => import('pages/customers')));
const CustomerInfo = Loadable(lazy(() => import('pages/customers/CustomerInfo')));
const JobTypesList = Loadable(lazy(() => import('pages/jobs/JobTypesList')));
const CreateJob = Loadable(lazy(() => import('pages/jobs/create-job')));
const JobDetails = Loadable(lazy(() => import('pages/jobs/Details')));
const EarningsReportPage = Loadable(lazy(() => import('pages/report/earnings')));
const GeneralReport = Loadable(lazy(() => import('pages/report/General')));
const Earning = Loadable(lazy(() => import('pages/report/earnings/Earning')));
const KpiReport = Loadable(lazy(() => import('pages/report/KpiReport')));
const MonthwiseReport = Loadable(lazy(() => import('pages/report/MonthwiseReport')));
const TechnicianJobHistory = Loadable(lazy(() => import('pages/technicians/TechnicianJobHistory')));
const PaymentList = Loadable(lazy(() => import('pages/payments/PaymentList')));
const AgreementList = Loadable(lazy(() => import('pages/agreements/AgreementList')));
const AgreementDetails = Loadable(lazy(() => import('pages/agreements/AgreementDetails')));
const SendPushPotifications = Loadable(
  lazy(() => import('pages/push-notifications/SendPushPotifications'))
);
const StaffList = Loadable(lazy(() => import('pages/staffs')));
const StaffDetails = Loadable(lazy(() => import('pages/staffs/StaffDetails')));
const RoleList = Loadable(lazy(() => import('pages/roles')));
const InspectionList = Loadable(lazy(() => import('pages/inspections/InspectionList')));
const LanguageList = Loadable(lazy(() => import('pages/languages/LanguageList')));
const StateList = Loadable(lazy(() => import('pages/states/StateList')));
const ShiftList = Loadable(lazy(() => import('pages/shifts/ShiftList')));
const ShiftDetails = Loadable(lazy(() => import('pages/shifts/ShiftDetails')));

const routes = (isLoggedIn: boolean) => [
  {
    path: '/',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [{ index: true, path: 'login', element: <AuthLogin /> }]
  },
  {
    path: '/',
    element: isLoggedIn ? (
      <Layout>
        <AnimatePresence mode="wait" initial={false}>
          <Box
            width={'calc(100vw - 290px)'}
            boxSizing={'border-box'}
            >
            <Outlet />
          </Box>
        </AnimatePresence>
      </Layout>
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: '', index: true, element: <DashBoard /> },
      { path: 'users', element: <Users /> },
      { path: 'garage', element: <Garage /> },
      { path: 'services', element: <Services /> },
      { path: 'services-category', element: <ServiceCategory /> },
      {
        path: 'jobs',
        children: [
          { index: true, path: '', element: <JobCard /> },
          // { index: true, path: '', element: <Jobs /> },
          { path: ':jobId', element: <JobDetails /> },
          {
            path: 'add-job',
            element: <CreateJob />
          },
          {
            path: 'agent',
            element: <AgentDashboard />
          },
          { path: 'inspections', element: <InspectionList /> }
        ]
      },
      {
        path: 'technicians',
        children: [
          { index: true, path: '', element: <Technicians /> },
          { path: ':technicianId', element: <TechnicianInfo /> },
          {
            path: ':technicianId',
            children: [
              { index: true, path: '', element: <TechnicianInfo /> },
              { path: 'rating', element: <TechnicianRating /> },
              { path: 'job-history', element: <TechnicianJobHistory /> }
            ]
          }
        ]
      },
      { path: 'payments', element: <PaymentList /> },
      {
        path: 'reports',
        children: [
          { index: true, path: '', element: <GeneralReport /> },
          {
            path: 'earnings',
            children: [
              {
                index: true,
                path: '',
                element: <EarningsReportPage />
              },
              { path: ':serviceCategoryID', element: <Earning /> }
            ]
          },
          { path: 'kpi', element: <KpiReport /> },
          { path: 'monthwise', element: <MonthwiseReport /> }
        ]
      },
      {
        path: 'staffs',
        children: [
          { index: true, path: '', element: <StaffList /> },
          { path: ':adminID', element: <StaffDetails /> }
        ]
      },
      {
        path: 'settings',
        children: [
          { index: true, path: '', element: <Settings /> },
          { path: 'vehicles', element: <Vehicle /> },
          { path: 'vehicles-brand', element: <VehicleBrandList /> },
          { path: 'document', element: <DocumentList /> },
          {
            path: 'push-notification',
            children: [
              { index: true, path: '', element: <ListNotifications /> },
              { path: 'new', element: <SendPushPotifications /> }
            ]
          },
          { path: 'designations', element: <RoleList /> },
          { path: 'Job-types', element: <JobTypesList /> },
          {
            path: 'agreements',

            children: [
              { index: true, path: '', element: <AgreementList /> },
              { path: ':agreementID', element: <AgreementDetails /> }
            ]
          },
          {
            path: 'banners',
            children: [
              { index: true, path: '', element: <BannerList /> },
              { path: ':bannerId', element: <BannerItem /> }
            ]
          },
          { path: 'languages', element: <LanguageList /> },
          { path: 'states', element: <StateList /> },
          {
            path: 'shifts',
            children: [
              { index: true, path: '', element: <ShiftList /> },
              { path: ':shiftID', element: <ShiftDetails /> }
            ]
          },
          {
            path: 'subscription-plans',
            children: [{ index: true, path: '', element: <SubscriptionPlansListing /> }]
          }
        ]
      },
      {
        path: 'customers',
        children: [
          { index: true, path: '', element: <Customers /> },
          { path: ':customerId', element: <CustomerInfo /> }
        ]
      },
      {
        path: 'agent-roster',
        children: [
          { index: true, path: '', element: <AgentRoster /> },
          {
            path: 'plan',
            children: [
              { index: false, path: 'create', element: <CreateRosterPlan /> },
              { index: true, path: '', element: <RosterPlan key="roster-plan" /> }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
