import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import AutoAidLogo from '../../assets/icons/autoaid-logo.png';
import QuickJob from 'pages/jobs/QuickJob';
import ability, { SUBJECTS } from 'common/casl/ability';
import HeaderIcons from './HeaderIcons';
import { useNavigate } from 'react-router-dom';
import { useAdminLogoutMutation } from 'app/services/auth';
import { store } from 'app/store';
import { logout } from 'pages/auth/authSlice';
import { Divider, Menu, MenuItem, Switch, Typography } from '@mui/material';
import UniversalSearchBar from './UniversalSearchBar';
import WorkStatus from './WorkStatus';
import { useGetAdminProfileQuery } from 'app/services/profileInfo';
import { setProfileData } from './ProfileSlice';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1
}));

const StyledSearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '25px',
  padding: '4px 12px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  width: '500px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const Header = ({ open, handleDrawerOpen }: { open: boolean; handleDrawerOpen: any }) => {
  const [openQuickJob, setOpenQuickJob] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [adminLogout] = useAdminLogoutMutation();

  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');

  const history = useNavigate();

  const { isLoading, isSuccess, isError, data, refetch } = useGetAdminProfileQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfileInfo = async () => {
      const profileInfo = await refetch();
      if (profileInfo.data) {
        dispatch(setProfileData(profileInfo.data));
      }
    };

    fetchProfileInfo();

    return () => {};
  }, [isLoading, isSuccess, isError, data]);

  const handleOpen = () => setOpenQuickJob(true);
  const handleClose = () => setOpenQuickJob(false);

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    adminLogout({});
    store.dispatch(logout());
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2
        }}>
        {/* Logo Section */}
        <Box display="flex" alignItems="center" gap={2} minWidth={200} justifyContent={'center'}>
          <img src={AutoAidLogo} alt="Auto Aid Logo" style={{ height: '50px' }} />
        </Box>

        {/* Search Bar */}
        {/* <StyledSearchBar>
          <IconButton size="small" sx={{ color: '#888' }}>
            <SearchIcon />
          </IconButton>
          <InputBase placeholder="Universal Search Bar" sx={{ flex: 1, ml: 1, fontSize: '14px' }} />
        </StyledSearchBar> */}

        <UniversalSearchBar />

        {/* Action Buttons */}
        <Box display="flex" alignItems="center" gap={2}>
          {ability.can('CREATE', SUBJECTS.JOBS) && (
            <Button variant="contained" onClick={handleOpen}>
              Quick Job
            </Button>
          )}

          {/* <HeaderIcons /> */}

          {/* Notification Icon */}
          <IconButton sx={{ color: '#888' }} onClick={() => history('/settings/push-notification')}>
            <NotificationsIcon />
          </IconButton>

          {/* User Icon */}
          {/* User Icon with Popup */}
          <IconButton sx={{ color: '#888' }} onClick={handleUserMenuOpen}>
            <PersonIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
            PaperProps={{
              elevation: 4,
              sx: {
                overflow: 'visible',
                mt: 1.5,
                boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                minWidth: '250px'
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {/* User Details */}
            <Box px={2} py={1}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body1" color="text.primary">
                  {user?.firstName} {user?.lastName}
                </Typography>
                {/* <PersonIcon /> */}
                <WorkStatus />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
            <Divider />

            {/* Logout Button */}
            <MenuItem onClick={handleLogout} sx={{ display: 'flex', justifyContent: 'center' }}>
              Logout
            </MenuItem>
          </Menu>

          {openQuickJob && (
            <QuickJob title="Add Quick Job" open={openQuickJob} handleClose={handleClose} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

/*
import { useState } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HeaderIcons from './HeaderIcons';
import QuickJob from 'pages/jobs/QuickJob';
import ability, { SUBJECTS } from 'common/casl/ability';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1
}));

const Header = ({ open, handleDrawerOpen }: { open: boolean; handleDrawerOpen: any }) => {
  const [openQuickJob, setOpenQuickJob] = useState<boolean>(false);
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');

  const handleOpen = () => setOpenQuickJob(true);
  const handleClose = () => {
    setOpenQuickJob(false);
  };

  return (
    <AppBar position="fixed" open={open} sx={{ bgcolor: 'white', boxShadow: 0.1 }}>
      <Toolbar>
        <Box
          display="flex"
          // alignItems='flex-end'
          alignItems="center"
          flexDirection="column"
          ml={2}>
          <Typography color="primary" noWrap fontSize="14px" textAlign="start">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography
            color="primary"
            noWrap
            component="div"
            fontSize="10px"
            textAlign="start"
            width="100%">
            {user?.staffID}
          </Typography>
        </Box>

        <Box sx={{ marginLeft: 'auto' }}>
          {ability.can('CREATE', SUBJECTS.JOBS) && (
            <Button
              variant="text"
              onClick={handleOpen}
              // startIcon={<AddIcon />}
              sx={{ height: '36px' }}>
              Quick Job
            </Button>
          )}
          <HeaderIcons />

          {openQuickJob && (
            <QuickJob title="Add Quick Job" open={openQuickJob} handleClose={handleClose} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
*/
