import { createTheme } from '@mui/material';
import { colors, spacing, fontSizes, borderRadius, borderWidth } from './themeConstants';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.primaryContrastText
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.secondaryContrastText
    },
    error: {
      main: colors.error
    },
    warning: {
      main: colors.warning
    },
    info: {
      main: colors.info
    },
    success: {
      main: colors.success
    },
    background: {
      default: colors.backgroundDefault,
      paper: colors.backgroundPaper
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary
    }
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Open Sans, Arial, sans-serif',
    h1: {
      fontSize: fontSizes.h1,
      fontWeight: 700,
      lineHeight: '45px',
      letterSpacing: '0.15px',
      color: colors.textPrimary
    },
    h2: {
      fontSize: fontSizes.h2,
      fontWeight: 700,
      lineHeight: '36px',
      letterSpacing: '0.15px',
      color: colors.textPrimary
    },
    h3: {
      fontSize: fontSizes.h3,
      fontWeight: 600,
      lineHeight: '24px',
      color: colors.textSecondary
    },
    h4: {
      fontSize: fontSizes.h4,
      fontWeight: 600,
      lineHeight: '19.2px',
      color: colors.textSecondary
    },
    body1: {
      fontSize: fontSizes.body1,
      fontWeight: 400,
      lineHeight: '23px',
      color: colors.textPrimary
    },
    body2: {
      fontSize: fontSizes.body2,
      fontWeight: 400,
      lineHeight: '21px',
      color: colors.textSecondary
    },
    button: {
      fontSize: fontSizes.button,
      fontWeight: 500,
      lineHeight: '24.5px',
      textTransform: 'uppercase',
      letterSpacing: '0.4px',
      color: colors.primaryContrastText
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.backgroundDefault,
          backgroundColor: colors.backgroundDefault,
          boxShadow: 'none'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          background: colors.backgroundDefault,
          backgroundColor: colors.backgroundDefault,
          boxShadow: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: colors.backgroundDefault,
          backgroundColor: colors.backgroundDefault,
          border: 'none'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-8px !important'
        },
        shrink: {
          top: '0px !important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: colors.primary,
          backgroundImage: colors.gradientPrimary,
          color: colors.primaryContrastText,
          borderRadius: borderRadius.button,
          textTransform: 'none',
          fontSize: fontSizes.button,
          fontWeight: 600,
          padding: spacing.buttonPadding,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: colors.primary,
            backgroundImage: colors.gradientPrimaryHover
          }
        },
        outlined: {
          borderRadius: borderRadius.button,
          textTransform: 'none',
          fontSize: fontSizes.button,
          fontWeight: 600,
          padding: spacing.buttonPadding,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: colors.primary,
            backgroundImage: colors.gradientPrimaryHover,
            color: colors.primaryContrastText
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.input,
          padding: spacing.inputContainerPadding,
          boxShadow: 'box-shadow: 10px 10px 19px -16px rgba(0,0,0,1);',
          '& fieldset': {
            borderColor: colors.inputBorder,
            border: `2px solid ${colors.inputBorder}`,
            borderWidth: borderWidth.input
          },
          '&:hover fieldset': {
            borderColor: colors.inputBorder,
            borderWidth: borderWidth.input
          },
          '&.Mui-focused fieldset': {
            borderColor: colors.inputBorder,
            borderWidth: borderWidth.input
          }
        },
        input: {
          fontSize: fontSizes.input,
          padding: spacing.inputPadding
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: spacing.inputContainerPadding
        },
        input: {
          fontSize: fontSizes.input,
          padding: spacing.inputPadding,
          boxSizing: 'border-box',
          textAlign: 'left'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: spacing.inputContainerPadding,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.inputBorder
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.inputBorder
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.inputBorder
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.backgroundDefault,
          borderRadius: borderRadius.dialog,
          padding: spacing.dialogPadding
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: spacing.inputContainerPadding
        },
        input: {
          fontSize: fontSizes.input,
          padding: spacing.inputPadding,
          boxSizing: 'border-box',
          textAlign: 'left'
        }
      }
    }
    // MuiPickersDay: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         background: colors.gradientPrimary,
    //         color: colors.primaryContrastText,
    //         borderRadius: borderRadius.calendar,
    //       },
    //       '&:hover': {
    //         background: colors.gradientPrimaryHover,
    //         color: colors.primaryContrastText,
    //       },
    //     },
    //     today: {
    //       border: `1px solid ${colors.primary}`,
    //       backgroundColor: 'rgba(230,245,255,1)',
    //     },
    //   },
    // },
  }
});

export const DataGridTheme = {
  // minHeight: '400px',
  '& .MuiDataGrid-virtualScroller': {
    minHeight: '200px'
  },
  border: 'none',
  '& .MuiDataGrid-cell': {
    borderBottom: 'none'
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#f9f9f9' // Light gray for odd rows
  },
  '& .MuiDataGrid-row:nth-of-type(even)': {
    backgroundColor: '#ffffff' // White for even rows
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#E0E9ED', // Light gray header background
    color: '#333333',
    fontWeight: '600 !important',
    textAlign: 'center',
    borderBottom: '2px solid #e0e0e0',
    height: '40px !important',
    minHeight: '40px !important'
  },
  '& .MuiDataGrid-columnHeader': {
    fontWeight: 'bold',
    padding: '0 8px'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textAlign: 'center',
    color: '#333333',
    fontWeight: '600 !important'
  },
  '& .MuiDataGrid-cellContent': {
    textAlign: 'center'
  },
  '& .MuiDataGrid-row': {
    height: 60
  }
};
