import { FC, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  Fade,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ThemedSelect from 'components/ThemedSelect';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import QueryString from 'qs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export type FilterOptionType = {
  label: string; // The display label for the option
  value: string | number; // The value corresponding to the option
};

export type FilterTemplateType = {
  type:
    | 'date-range'
    | 'select'
    | 'multi-select'
    | 'radio-button'
    | 'switch'
    | 'text'
    | 'range'
    | 'checkbox';
  label: string;
  fieldKey: string;
  options?: FilterOptionType[]; // Used for select, multi-select, checkbox, and radio-button filters
  whereOption?: 'between' | 'eq' | 'in' | 'range'; // SQL-like condition for filtering
  inShowMoreSection?: boolean;
};

export const ThemedFiltersModal: FC<any> = ({
  filtersObj,
  setFiltersObj,
  modalOpen,
  closeFilterModal,
  filterTemplate,
  handleFilterClear,
  applyFilters,
  clearFilters
}) => {
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  const toggleMoreFilters = () => {
    setShowMoreFilters((prev) => !prev);
  };

  const handleMultiSelectChange = (e: any, fieldKey: string) => {
    const f: any = { ...filtersObj };
    f[fieldKey] = e;

    setFiltersObj(f);
  };

  const handleDateRangeChange = (e: any, fieldKey: string, fromOrTo: string) => {
    const f: any = { ...filtersObj };
    const val = filtersObj?.[fieldKey] ? filtersObj?.[fieldKey] : [];
    let from = val?.[0] || moment();
    let to = val?.[1] || moment();

    if (fromOrTo === 'from') {
      from = e;
    }

    if (fromOrTo === 'to') {
      to = e;
    }

    f[fieldKey] = [from, to];
    setFiltersObj(f);
  };

  const handleTemplateMapping = (item: FilterTemplateType, index: number) => {
    if (item.type === 'switch') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          {/* Hide the title */}
          {/* <Typography variant="h6">{item.label}</Typography> */}
          <Button
            variant={filtersObj?.[item.fieldKey] ? 'contained' : 'outlined'}
            onClick={() =>
              setFiltersObj((prev: any) => ({
                ...prev,
                [item.fieldKey]: !prev[item.fieldKey]
              }))
            }>
            {item.label}: {filtersObj?.[item.fieldKey] ? 'Enabled' : 'Disabled'}
          </Button>
        </Box>
      );
    }

    if (item.type === 'text') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <TextField
            fullWidth
            variant="outlined"
            label={item.label}
            value={filtersObj?.[item.fieldKey] || ''}
            onChange={(e) =>
              setFiltersObj((prev: any) => ({
                ...prev,
                [item.fieldKey]: e.target.value
              }))
            }
          />
        </Box>
      );
    }

    if (item.type === 'range') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          {/* Hide the title */}
          {/* <Typography variant="h6">{item.label}</Typography> */}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <input
                type="number"
                placeholder="Min"
                value={filtersObj?.[item.fieldKey]?.min || ''}
                onChange={(e) => {
                  const min = e.target.value ? parseFloat(e.target.value) : '';
                  setFiltersObj((prev: any) => ({
                    ...prev,
                    [item.fieldKey]: { ...prev[item.fieldKey], min }
                  }));
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <input
                type="number"
                placeholder="Max"
                value={filtersObj?.[item.fieldKey]?.max || ''}
                onChange={(e) => {
                  const max = e.target.value ? parseFloat(e.target.value) : '';
                  setFiltersObj((prev: any) => ({
                    ...prev,
                    [item.fieldKey]: { ...prev[item.fieldKey], max }
                  }));
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (item.type === 'checkbox') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Grid container spacing={1}>
            {item.options?.map((option) => (
              <Grid item key={option.value.toString() + '-checkbox'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filtersObj?.[item.fieldKey] === 'Y'}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFiltersObj((prev: any) => ({
                          ...prev,
                          [item.fieldKey]: checked ? 'Y' : 'N'
                        }));
                      }}
                    />
                  }
                  label={option.label}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }

    if (item.type === 'date-range') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Grid container key={`${item.fieldKey}-${index}-container`}>
            <Grid item xs={12}>
              <Button variant="contained">{item.label}</Button>
            </Grid>

            <Grid item xs={5.5} mt={2}>
              <DateTimePicker
                name={`${item.fieldKey}-from`}
                label={'From'}
                autoFocus={false}
                sx={{ width: '100%' }}
                onChange={(e) => handleDateRangeChange(e, item.fieldKey, 'from')}
                value={filtersObj?.[item?.fieldKey]?.[0] || null}
                format="DD-MM-YYYY"
              />
            </Grid>

            <Grid item xs={1} mt={2} display="flex" justifyContent="center" alignItems="center">
              -
            </Grid>

            <Grid item xs={5.5} mt={2}>
              <DateTimePicker
                name={`${item.fieldKey}-to`}
                label={'To'}
                autoFocus={false}
                sx={{ width: '100%' }}
                onChange={(e) => handleDateRangeChange(e, item.fieldKey, 'to')}
                value={filtersObj?.[item?.fieldKey]?.[1] || null}
                format="DD-MM-YYYY"
              />
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (item.type === 'radio-button') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Typography variant="h6">{item.label}</Typography>
          <Grid container spacing={1}>
            {item.options?.map((option) => (
              <Grid item key={option.value.toString() + 'item-key' + option.label}>
                <Button
                  variant={filtersObj?.[item.fieldKey] === option.value ? 'contained' : 'outlined'}
                  onClick={() =>
                    setFiltersObj((prev: any) => ({
                      ...prev,
                      [item.fieldKey]: option.value
                    }))
                  }
                  style={{ textTransform: 'capitalize' }}>
                  {option.label.toLowerCase().replace(/_/g, ' ')}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }

    return (
      <Box key={index} sx={{ my: 1.5, width: '100%' }}>
        <ThemedSelect
          //@ts-ignore
          options={item.options}
          handleClear={handleFilterClear}
          isMulti={item.type === 'multi-select'}
          placeholder={item.label}
          onChange={(e) => handleMultiSelectChange(e, item.fieldKey)}
          value={filtersObj?.[item?.fieldKey] || []}
          isClearable={true}
          onClear={() => {}}
        />
      </Box>
    );
  };

  const hasMoreFilters = filterTemplate?.some((el: any) => el?.inShowMoreSection);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeFilterModal}
      sx={{
        '.MuiPaper-root': { background: 'white' }
      }}>
      <DialogContent
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          minWidth: '400px',
          width: '400px'
        }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Fade in={modalOpen}>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {filterTemplate
                ?.filter((el: any) => !el?.inShowMoreSection)
                .map(handleTemplateMapping)}

              {hasMoreFilters && (
                <Button
                  variant="text"
                  onClick={toggleMoreFilters}
                  sx={{ my: 2, fontSize: '16px', textTransform: 'capitalize' }}
                  startIcon={showMoreFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {showMoreFilters ? 'Hide Extra Filters' : 'More Filters'}
                </Button>
              )}

              <Collapse in={showMoreFilters} timeout={500} sx={{ width: '100%' }}>
                <Box>
                  {filterTemplate
                    ?.filter((el: any) => el?.inShowMoreSection)
                    .map(handleTemplateMapping)}
                </Box>
              </Collapse>

              <Box sx={{ my: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={() => applyFilters()}>
                  Apply Filter
                </Button>
              </Box>

              <Button
                variant="text"
                onClick={clearFilters}
                sx={{
                  mt: 0,
                  p: 0,
                  color: '#BABABA',
                  fontsize: '16px',
                  textTransform: 'capitalize'
                }}>
                Clear Filter
              </Button>
            </Box>
          </Fade>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

const useThemedFilter = ({ clearParentFilters, filterTemplate }: any) => {
  const [filtersQuery, setFiltersQuery] = useState<string>('');
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [filtersObj, setFiltersObj] = useState<any>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFiltersQuery(value);
    setFiltersObj((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFilterClear = useCallback(() => {
    setFiltersQuery('');
    setFiltersObj({});
  }, []);

  const openFilterModal = () => {
    setModalOpen(true);
    setFiltersObj(appliedFilters);
  };

  const closeFilterModal = () => {
    setFiltersObj({});
    if (clearParentFilters) clearParentFilters();
    setModalOpen(false);
  };

  const applyFilters = (supplied?: any) => {
    if (supplied) {
      setAppliedFilters(supplied);
      if (modalOpen) closeFilterModal();
    } else {
      setAppliedFilters(filtersObj);
      if (modalOpen) closeFilterModal();
    }
  };

  const clearFilters = useCallback(() => {
    setFiltersObj({});
    setAppliedFilters({});
    if (clearParentFilters) clearParentFilters();
    setModalOpen(false);
  }, []);

  const handleChipDelete = (item: any) => {
    setAppliedFilters((prevAppliedFilters: any) => {
      const updatedFilters = { ...prevAppliedFilters };
      const whereOption = getFieldWhereOption(item.key);
      if (Array.isArray(updatedFilters[item.key]) && whereOption === 'in') {
        updatedFilters[item.key] = updatedFilters[item.key].filter(
          (selectedItem: any) => selectedItem.value !== item.value
        );
        // If the array is empty after removal, delete the key
        if (updatedFilters[item.key].length === 0) {
          delete updatedFilters[item.key];
        }
      }

      if (Array.isArray(updatedFilters[item.key]) && whereOption === 'between') {
        delete updatedFilters[item.key];
      }
      return updatedFilters;
    });
    if (clearParentFilters) clearParentFilters();
  };

  const getFieldWhereOption = (fieldKey: string) => {
    const field = filterTemplate.find((item: any) => item.fieldKey === fieldKey);
    return field?.whereOption;
  };

  const RenderChipsView = useCallback(() => {
    const chips: any[] = [];

    for (const key in appliedFilters) {
      if (typeof appliedFilters[key] === 'string') {
      }

      if (typeof appliedFilters[key] === 'object' && appliedFilters[key]?.length > 0) {
        const whereOpt = getFieldWhereOption(key);
        if (whereOpt === 'in') {
          appliedFilters[key].forEach((item: any) => {
            chips.push({
              label: item.label,
              value: item.value,
              key: key
            });
          });
        }

        if (whereOpt === 'between') {
          const from = appliedFilters[key]?.[0];
          const to = appliedFilters[key]?.[1];
          chips.push({
            label: `${from.format('DD-MM-YYYY')} - ${to.format('DD-MM-YYYY')}`,
            value: `${from} - ${to}`,
            key: key
          });
        }
      }
    }

    return (
      <Box display="flex" flexWrap="wrap" gap={3} my={2}>
        {chips.map((item, idx) => (
          <Chip label={item.label} key={idx} onDelete={() => handleChipDelete(item)} />
        ))}
      </Box>
    );
  }, [filtersObj, appliedFilters]);

  const handleAppliedFiltersChange = useCallback(
    (appliedFilters: any) => {
      const queryObj: any = {};

      for (const key in appliedFilters) {
        if (typeof appliedFilters[key] === 'string') {
          queryObj[key] = appliedFilters[key];
        }

        if (typeof appliedFilters[key] === 'object' && appliedFilters[key]?.length > 0) {
          const whereOpt = getFieldWhereOption(key);
          if (whereOpt === 'in') {
            queryObj[key] = appliedFilters[key]?.map((item: any) => item.value);
          }

          if (whereOpt === 'between') {
            const from = appliedFilters[key]?.[0];
            const to = appliedFilters[key]?.[1];
            queryObj[key] = [from.toDate(), to.toDate()];
          }
        }
      }

      setFiltersQuery(QueryString.stringify(queryObj));
    },
    [filtersObj]
  );

  useEffect(() => {
    handleAppliedFiltersChange(appliedFilters);
  }, [appliedFilters, handleAppliedFiltersChange]);

  return {
    filtersQuery,
    filtersObj,
    handleFilterChange,
    handleFilterClear,
    openFilterModal,
    closeFilterModal,
    modalOpen,
    RenderChipsView,
    appliedFilters,
    setFiltersObj,
    applyFilters,
    clearFilters
  };
};

export default useThemedFilter;
