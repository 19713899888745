import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import {
  useAdminGetRosterPlanReportUrlMutation,
  useGetAgentRosterMasterDataQuery,
  useGetAgentRosterPlanQuery
} from 'app/services/shift';
import { useEffect, useState } from 'react';
import { spacing } from 'utils/themeConstants';
import CustomDataGrid from './components/test-table';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DownloadOutlined } from '@mui/icons-material';

const RosterPlan = () => {
  const nav = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [formattedEntries, setFormattedEntries] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState({
    title: moment().format('MMM YY'),
    value: moment().format('MM-YY')
  });
  const open = Boolean(anchorEl);

  const [getDownloadUrl, { isLoading: reportLoading }] = useAdminGetRosterPlanReportUrlMutation({});

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: any) => {
    if (value) {
      setSelectedValue({
        title: moment(
          value.planMonth.toString().padStart(2, '0') + '-' + value.planYear,
          'MM-YYYY'
        ).format('MMM YY'),
        value: value.planMonth.toString().padStart(2, '0') + '-' + value.planYear
      });
    } else {
      setSelectedValue({
        title: moment().format('MMM YY'),
        value: moment().format('MM-YY')
      });
    }
    setAnchorEl(null);
  };

  const { data: masterData, isLoading: isMasterDataLoading } = useGetAgentRosterMasterDataQuery();

  const { data, isLoading, refetch, isFetching } = useGetAgentRosterPlanQuery({
    planMonth: selectedValue.value.split('-')[0],
    planYear: selectedValue.value.split('-')[1]
  });

  useEffect(() => {
    refetch();
  }, [selectedValue]);

  const handleBack = () => {
    nav(-1);
  };

  return (
    <Grid container>
      {isMasterDataLoading || isLoading || isFetching ? (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          justifyContent={'center'}
          minHeight={'70vh'}
          alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid item xs={6} sx={{ mb: 4 }}>
            <Tooltip title="Go Back" arrow>
              <IconButton
                onClick={handleBack}
                sx={{
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#f0f8ff'
                  },
                  marginRight: '1rem',
                  padding: 0
                }}>
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            <Button
              variant="outlined"
              sx={{
                color: 'black',
                borderColor: 'black',
                textTransform: 'none',
                fontSize: '14px',
                padding: spacing.buttonPadding,
                '&:hover': {
                  borderColor: 'gray'
                },
                marginRight: '1rem'
              }}
              onClick={handleClick}>
              {selectedValue.title}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(null)}
              MenuListProps={{
                'aria-labelledby': 'dropdown-button'
              }}>
              {masterData?.availablePlans.map((item: any) => (
                <MenuItem key={item.id + 'available-plans'} onClick={() => handleClose(item)}>
                  {moment(
                    item.planMonth.toString().padStart(2, '0') + '-' + item.planYear,
                    'MM-YYYY'
                  ).format('MMM YY')}
                </MenuItem>
              ))}
            </Menu>

            <Button
              variant="outlined"
              size="small"
              onClick={() => nav('/agent-roster/plan/create')}>
              Create Plan
            </Button>
          </Grid>

          <Grid item xs={6} sx={{ mb: 4 }} display="flex" justifyContent="flex-end">
            <IconButton
              disabled={reportLoading}
              onClick={() => {
                const availablePlans = masterData?.availablePlans;

                if (availablePlans && availablePlans?.length > 0) {
                  const currentMonth = parseInt(selectedValue.value.split('-')[0], 10);
                  const currentYear = parseInt(selectedValue.value.split('-')[1], 10);

                  const plan = availablePlans.find(
                    (plan: any) => plan.planYear === currentYear && plan.planMonth === currentMonth
                  );

                  if (plan) {
                    getDownloadUrl({
                      planID: plan.planID,
                      reportType: 'AGENT_ROSTER_REPORT'
                    }).then((res: any) => {
                      if (res?.data?.data) {
                        window.open(res?.data?.data, '_blank');
                      }
                    });
                  } else {
                    console.error('No matching plan found for the selected month and year.');
                  }
                } else {
                  console.error('No available plans to select from.');
                }
              }}>
              {reportLoading ? <CircularProgress size={20} /> : <DownloadOutlined />}
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            {!data?.plan ? (
              <Box
                key="No plan created text box"
                width={'100%'}
                height={'100%'}
                display={'flex'}
                justifyContent={'center'}
                minHeight={'70vh'}
                alignItems={'center'}>
                <Typography variant="h6" color="secondary" fontWeight={700}>
                  No Plan Created
                </Typography>
              </Box>
            ) : (
              <CustomDataGrid
                data={data}
                isLoading={isLoading}
                masterData={masterData}
                onPlanChange={undefined}
                formattedEntries={formattedEntries}
                setFormattedEntries={setFormattedEntries}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RosterPlan;
